import MediaPreviewEmbed from '../MediaPreviewEmbed/MediaPreviewEmbed';

const getActualUrl = (url: string) => {
  try {
    const parsed = new URL(url);
    const { pathname } = parsed;
    if (!pathname.startsWith('/shorts/')) {
      return '';
    }
    const idPart = pathname.slice('/shorts/'.length);
    const actualUrl = `https://youtube.com/watch?v=${idPart}`;
    return actualUrl;
  } catch {
    return '';
  }
};

function MediaPreviewYoutubeShort({
  url,
  heightOfIframe,
}: {
  url: string;
  heightOfIframe?: number;
}) {
  // hack
  const actualUrl = getActualUrl(url);
  if (!actualUrl) {
    return <div>Youtube Short URL could not be parsed</div>;
  }
  return <MediaPreviewEmbed url={actualUrl} heightOfIframe={heightOfIframe} />;
}

export default MediaPreviewYoutubeShort;
